import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
import storage from 'store'
import store from './store'
import { ACCESS_TOKEN, USER_INFO, ADD_KEEP_ALIVE, CLEAR_KEEP_ALIVE } from '@/store/mutation-types'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('./view/login.vue'),
        meta: {
          title: '登录',
          keepAlive: false,
          isPublic: true,
        },
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('./view/index.vue'),
        meta: {
          title: '美好生活相信惠到家',
          keepAlive: false,
          hasTabbar: true
        },
      },
      {
        path: '/order-confirm',
        name: 'order-confirm',
        component: () => import('./view/order/confirm.vue'),
        meta: {
          title: '确认订单',
          keepAlive: true
        },
      },
      {
        path: '/order-success',
        name: 'order-success',
        component: () => import('./view/order/success.vue'),
        meta: {
          title: '下单成功',
          keepAlive: false,
        },
      },
      {
        path: '/order-logs',
        name: 'order-logs',
        component: () => import('./view/order/logs.vue'),
        meta: {
          title: '兑换记录',
          keepAlive: false
        }
      },
      {
        path: '/agreement',
        name: 'agreement',
        component: () => import('./view/agreement.vue'),
        meta: {
          title: '个人信息保护政策',
          keepAlive: false,
          isPublic: true,
        },
      },
      {
        path: '/address-add',
        name: 'address-add',
        component: () => import('./view/address/edit.vue'),
        meta: {
          title: '新增收货地址',
          keepAlive: false,
        },
      },
      {
        path: '/address-edit/:id',
        name: 'address-edit',
        component: () => import('./view/address/edit.vue'),
        meta: {
          title: '编辑收货地址',
          keepAlive: false,
        },
      },
      {
        path: '/policy',
        name: 'policy',
        component: () => import('./view/policy.vue'),
        meta: {
          title: '隐私政策',
          keepAlive: false,
          isPublic: true,
        }
      }
      
    ]
  }
]

routes.forEach((route) => {
  route.path = route.path || '/' + (route.name || '')
})

const router = new Router({
  mode: 'history',
  routes,
})

const allowList = ['login', 'agreement', 'modal'] // no redirect allowList
const loginRoutePath = '/login'
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // next()
  /* 是否登录 */
  if (storage.get(ACCESS_TOKEN)) {
    if (to.path === loginRoutePath) {
      store.commit(CLEAR_KEEP_ALIVE)
      next({ path: defaultRoutePath })
    } else {
      // check login userInfo is null
      if (!store.getters.userInfo) {
        store.commit(CLEAR_KEEP_ALIVE)
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then((res) => {
            // 请求带有 redirect 重定向时，登录自动重定向到该地址
            const redirect = decodeURIComponent(from.query.redirect || to.path)
            if (to.path === redirect) {
              // set the replace: true so the navigation will not leave a history record
              next({ ...to, replace: true })
            } else {
              // 跳转到目的路由
              next({ path: redirect })
            }
          })
          .catch((err) => {
            console.log(err)
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            })
          })
      } else {
        if (to.meta.keepAlive) {
          store.commit(ADD_KEEP_ALIVE, [
            to.name
          ])
        }
        next()
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
    }
  }
})

export { router }
