import request from '@/utils/request'

// 登录
export function login(parameter) {
  return request({
    url: '/api/client/office-account/authenticate',
    method: 'post',
    data: parameter,
  })
}

// 发送短信验证码
export function sendCode(params) {
  return request({
    url: '/api/client/member/phone-code',
    method: 'get',
    params,
  })
}

/**
 * 发送登录短信验证码
 * @param {*} params 
 * @returns 
 */
export function sendLoginCode(params) {
  return request({
    url: '/api/client/office-account/phone-code',
    method: 'get',
    params,
  })
}


// 测试登录信息
export function getTestUser() {
  return request({
    url: '/api/client/member/test',
    method: 'get',
  })
}

// 当前会员登录信息
export function getMemberMe() {
  return request({
    url: '/api/client/office-account/me',
    method: 'get',
  })
}

// 校验短信验证码
export function verifyCode(params) {
  return request({
    url: '/api/client/getKey',
    method: 'get',
    params,
  })
}
