import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import Vant from 'vant'
import VueClipboard from 'vue-clipboard2'
import 'vant/lib/index.css'
import './assets/main.css'
import YSF from '@neysf/qiyu-web-sdk'

YSF.init('671f5d70168461f507085c38d7fc3d47', {
  hidden: 1
})

const req = require.context('@/assets/svg',false,/\.svg$/)
const requireAll = requireContext =>{
    requireContext.keys().map(requireContext)
} 
requireAll(req)

Vue.use(Vant)
Vue.use(VueClipboard)

Vue.config.productionTip = false

// 距离显示一位小数
Vue.prototype.$distanceFormat = function (number) {
  if (typeof number !== 'number') {
    return number
  }
  return number.toFixed(1)
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
