import Vue from 'vue'
import Vuex from 'vuex'
// import { getAction, postAction } from '@/api/manage'
import user from './modules/user'
import keepAlive from './modules/keep-alive'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    keepAlive
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {
    userInfo: (state) => state.user.info,
    userVip: (state) => state.user.vip,
    keepAlives: state => state.keepAlive.routes
  },
})
